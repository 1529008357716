import { useMemo } from 'react'

import { trackConversion } from '../integration-google-ads'
import { getMatomoTracker } from '@consent-manager/integration-matomo'

export const useTrackContactEvent = () => {
  const { trackEvent } = useMemo(getMatomoTracker, [getMatomoTracker])

  return (type) => {
    trackEvent('Contact', type, 'clicked', 'Sidebar')
    if (type === 'Phone') {
      trackConversion({ id: 'AW-1055014445/5DehCKbi1pEDEK38iPcD' })
    }
    if (type === 'Email') {
      trackConversion({ id: 'AW-1055014445/hgV4CPfl1pEDEK38iPcD' })
    }
  }
}
