import React, { useEffect, useMemo, useState } from 'react'
import { useLocalStorage } from 'react-use'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'

import Popup from '../popup'
import Button from '../form/fields/button'
import CTA from '../mdx/link/cta'
import Link from '../mdx/link/link-renderer'

const PopUp = ({ pageContext }) => {
  const { t } = useTranslation()

  const popupResults = useStaticQuery(graphql`
    query Popup {
      allContentfulContentTypeBlogPost(
        filter: {
          popupStart: { ne: null }
          popupEnd: { ne: null }
          popupImage: { id: { ne: null } }
        }
        sort: { popupStart: DESC }
      ) {
        nodes {
          title
          sys {
            id
            locale
          }
          popupStart
          popupEnd
          popupImage {
            gatsbyImageData(width: 600, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  // Loop through results and find first that should be active right now and locale matches
  const activePopup = useMemo(
    () =>
      popupResults.allContentfulContentTypeBlogPost.nodes.find(
        ({
          popupStart,
          popupEnd,

          sys: { locale: node_locale },
        }) => {
          if (node_locale !== pageContext.locale) {
            return false
          }
          const start = Date.parse(popupStart)
          const end = Date.parse(popupEnd)
          const now = Date.now()
          return start <= now && end >= now
        }
      ),
    [popupResults, pageContext.locale]
  )
  const [popupShown, setPopupShown] = useLocalStorage(
    `popup-${activePopup?.contentful_id}`
  )
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    if (!popupShown || popupShown <= Date.now() - 8 * 60 * 60 * 1000) {
      // Show popup only every 8 hours
      setPopupOpen(true)
      setPopupShown(Date.now())
    }
  }, [popupShown, setPopupOpen, setPopupShown])

  if (!activePopup) {
    return null
  }

  return (
    <Popup isOpen={popupOpen} setIsOpen={setPopupOpen}>
      <Link id={activePopup.sys.id} onClick={() => setPopupOpen(false)}>
        <GatsbyImage
          image={activePopup.popupImage.gatsbyImageData}
          alt={activePopup.title}
        />
      </Link>
      <div className="mt-8 flex flex-row-reverse justify-between">
        <CTA
          id={activePopup.sys.id}
          onClick={() => setPopupOpen(false)}
          data-button-style="primary"
        >
          {t('newsReadMore')}
        </CTA>
        <Button onClick={() => setPopupOpen(false)}>{t('popupClose')}</Button>
      </div>
    </Popup>
  )
}

export default PopUp
