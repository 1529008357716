import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import { translations, langs, defaultLocale } from '../page-config'

let instance = null
export function useI18n(language) {
  if (!instance || instance.language !== language) {
    const i18n = i18next.createInstance().use(initReactI18next)
    i18n.init({
      resources: translations,
      supportedLngs: langs,
      debug: process.env.NODE_ENV === 'development',
      fallbackLng: defaultLocale,
      load: 'currentOnly',
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      react: {
        useSuspense: false,
      },
    })

    if (i18n.language !== language) {
      i18n.changeLanguage(language)
    }
  }
  return instance
}
