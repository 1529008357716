import React, { forwardRef, useContext } from 'react'
import propTypes from 'prop-types'
import clsx from 'clsx/lite'

import MdxSuiteContext from '../../contexts/mdx-suite'

import MenuLink from './menu-link'
import MenuLi from './menu-li'
import MenuTitle from './menu-title'

const MenuItem = forwardRef(
  (
    {
      activeTrail,
      className,
      title,
      sys,
      internalTargetId,
      internalSlug,
      externalUri,
      openInNewTab,
      hiddenOnMobile,
      children,
    },
    ref
  ) => {
    const {
      pageContext: { pageId: activePageId },
    } = useContext(MdxSuiteContext)

    // Menu item links to current page or is part of the active trail
    const isActive =
      activeTrail.includes(sys.menuItemId) || internalTargetId === activePageId

    const activeClass = isActive && 'active'
    const hiddenClass = hiddenOnMobile && `hidden xl:block`

    let content = (
      <MenuTitle className={clsx(className, activeClass, hiddenClass)}>
        {title}
      </MenuTitle>
    )

    const linkProps = {
      className: clsx(className, activeClass),
      title,
      openInNewTab,
    }

    if (internalTargetId) {
      content = <MenuLink {...linkProps} id={internalTargetId} />
    }

    if (internalSlug) {
      content = <MenuLink {...linkProps} to={internalSlug} />
    }

    if (externalUri) {
      content = <MenuLink {...linkProps} href={externalUri} />
    }

    return (
      <MenuLi
        key={sys.menuItemId}
        className={clsx(className, activeClass, hiddenClass)}
        ref={ref}
      >
        {content}
        {children}
      </MenuLi>
    )
  }
)

MenuItem.propTypes = {
  activeTrail: propTypes.array.isRequired,
  className: propTypes.string,
  sys: propTypes.object.isRequired,
  title: propTypes.string,
  internalTargetId: propTypes.string,
  internalSlug: propTypes.string,
  externalUri: propTypes.string,
  children: propTypes.node,
  openInNewTab: propTypes.bool,
  hiddenOnMobile: propTypes.bool,
}

export default MenuItem
