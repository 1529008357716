import React from 'react'
import LoadingPlaceholder from '../lazy/loading'

const ContactForm = React.lazy(
  () => import(/* webpackChunkName: "contact-form" */ './contact-form')
)
const ContactFormWrapper = (props) => (
  <React.Suspense fallback={<LoadingPlaceholder />}>
    <ContactForm {...props} />
  </React.Suspense>
)
export default ContactFormWrapper
