import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTrackContactEvent } from '../hooks/tracking'
import Icon from './mdx/copy/icon'
import Link from './mdx/link/link'
import MenuLevel from './menu/menu-level'
import MenuRecursive from './menu/menu-recursive'
import clsx from 'clsx/lite'

const CopyRightMenu = ({ className }) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <p className="!mb-0">
        {t('copyright', { year: new Date().getFullYear() })} Stanford &amp;
        Ackel
      </p>
      <nav className={clsx('flex', 'md:inline-flex')}>
        <MenuLevel
          rootMenuItemId="3VwLVcNHulF5MghT11r3Xh"
          className="copyright-menu"
        />
      </nav>
    </div>
  )
}

export const Footer = () => {
  const { t } = useTranslation()
  const trackContactEvent = useTrackContactEvent()

  return (
    <footer className="mb-16 border-t border-secondary">
      <div
        className={clsx(
          'py-10 grid gap-x-8 gap-y-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center md:text-left',
          'center-to-content-column'
        )}
      >
        <section className="flex flex-col justify-between">
          <h1 aria-label="Stanford & Ackel" className="mb-4">
            <img
              src="/stanford-ackel-logo-full.svg"
              className="inline-block w-64"
              alt="Stanford &amp; Ackel"
              title="Stanford &amp; Ackel Logo"
              loading="lazy"
              fetchpriority="low"
              width="256"
              height="115"
            />
          </h1>
          <p className="text-lg my-2 leading-tight">{t('footerDescription')}</p>
          <CopyRightMenu className="hidden md:block" />
        </section>
        <section>
          <div className="h-16">
            <h2 className="text-3xl font-medium" as="h2">
              {t('footerHeadlineGetInTouch')}
            </h2>
          </div>
          <h3
            className="text-base text-secondary uppercase font-medium font-body mt-2 !mb-0"
            as="h3"
          >
            {t('footerHeadlineContactUs')}
          </h3>
          Stanford &amp; Ackel Educational Consultants
          <br />
          Widenmayerstr. 49
          <br />
          {t('footerContactCity')}
          <h3
            className="text-base text-secondary uppercase font-medium font-body mt-2 !mb-0"
            as="h3"
          >
            {t('footerHeadlineEmailUs')}
          </h3>
          <Link
            href="mailto:info@stanford-ackel.com"
            onClick={() => trackContactEvent('Email')}
            className="hover:text-secondary no-underline"
          >
            info@stanford-ackel.com
          </Link>
          <h3
            className="text-base text-secondary uppercase font-medium font-body mt-2 !mb-0"
            as="h3"
          >
            {t('footerHeadlineCallUs')}
          </h3>
          <Link
            href="tel:+498960013850"
            onClick={() => trackContactEvent('Phone')}
            className="hover:text-secondary no-underline"
          >
            + 49 (0)89 60 01 38 50
          </Link>
          <h3
            className="text-base text-secondary uppercase font-medium font-body mt-2 !mb-0"
            as="h3"
          >
            {t('footerHeadlineConnectWithUs')}
          </h3>
          <Link
            className="text-xl mr-4"
            href="https://www.facebook.com/stanford.ackel"
            openInNewTab
          >
            <Icon icon="facebook" title="Follow us on Facebook" />
          </Link>
          <Link
            className="text-xl"
            href="https://www.instagram.com/stanford_ackel/"
            openInNewTab
          >
            <Icon icon="instagram" title="Follow us on Instagram" />
          </Link>
        </section>
        <section className="hidden md:block md:col-span-2 lg:col-span-1">
          <div className="lg:h-16" />
          <h3
            className="text-base text-secondary uppercase font-medium font-body mt-2 !mb-0"
            as="h3"
          >
            {t('footerHeadlineQuickLinks')}
          </h3>
          <nav className="quick-links-menu">
            <MenuRecursive rootMenuItemId="2G4dtP5yvtku3Z5S43WgXv" />
          </nav>
        </section>
        <section className="md:hidden">
          <CopyRightMenu />
        </section>
      </div>
    </footer>
  )
}
