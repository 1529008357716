import React from 'react'

// Basics
import Image from './components/mdx/image/image'

// Layout
import Center from './components/mdx/layout/center'
import Column from './components/mdx/layout/column'
import Gap from './components/mdx/layout/gap'
import Section from './components/mdx/layout/section'

// Copy
import ListItem from './components/mdx/copy/list-item'
import Font from './components/mdx/copy/font'
import TextBlock from './components/mdx/copy/text-block'
import Icon from './components/mdx/copy/icon'

import Anchor from './components/mdx/scroll-effects/anchor'
import AnchorHook from './components/mdx/scroll-effects/anchor-hook'

// Project specific components
import BlogPostListing from './components/mdx/blog-post-listing'
import Header from './components/mdx/header'
import CTA from './components/mdx/link/cta'
import Separator from './components/mdx/separator'
import SectionHeader from './components/mdx/section-header'
import ColumnOverlap from './components/mdx/column-overlap'
import ContactForm from './components/mdx/contact-form-loadable'
import NarrowColumn from './components/mdx/layout/narrow-section'
import ShadowImage from './components/mdx/shadow-image'
// import TextColumns from '../components/mdx/text-columns' unused by Customer
// import DoubleSeparator from '../components/mdx/double-separator'
// import Download from '../components/mdx/download'
import Link from './components/mdx/link/link'
import Claim from './components/mdx/copy/claim'
import HugeOverlap from './components/mdx/huge-overlap'
import Columns from './components/mdx/columns'
import List from './components/mdx/copy/list'
import BookingForm from './components/mdx/booking-form-loadable'

const mdxComponents = {
  Image,
  Center,
  Column,
  Gap,
  Section,
  Font,
  TextBlock,
  Icon,
  BlogPostListing,
  CTA,
  Header,
  Link,
  NarrowColumn,
  Separator,
  SectionHeader,
  ColumnOverlap,
  ContactForm,
  ShadowImage,
  Claim,
  HugeOverlap,
  Columns,
  Anchor,
  AnchorHook,
  BookingForm,
  // Default element overrides
  h1: (props) => <Claim tag="h2" responsiveSize="2xl" {...props} />,
  h2: (props) => <Claim tag="h3" responsiveSize="xl" {...props} />,
  h3: (props) => <Claim tag="h4" responsiveSize="lg" {...props} />,
  h4: (props) => <Claim tag="h5" responsiveSize="md" {...props} />,
  h5: (props) => <Claim tag="h6" responsiveSize="sm" {...props} />,
  h6: (props) => <Claim tag="h6" size="base" {...props} />,
  a: (props) => <Link {...props} />,
  List,
  ul: (props) => <List type="unordered" {...props} />,
  ol: (props) => <List type="ordered" {...props} />,
  ListItem,
  li: ListItem,
}

export default mdxComponents
