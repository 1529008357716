import {
  createIconComponentFromSimpleIconsSvgPath,
  getForegroundColor,
  IntegrationConfig,
  useIntegration,
  useDecision,
} from '@consent-manager/core'

import { siGoogleads } from 'simple-icons'

let wasInitialized = false

interface TrackConversion {
  id: string
}

export function trackConversion({ id }: TrackConversion): boolean | Error {
  if (typeof gtag === 'function') {
    gtag('event', 'conversion', {
      send_to: id,
      // event_callback: callback,
    })
  }
  return false
}

declare global {
  interface Window {
    dataLayer: unknown[]
  }
}

const WrapperComponent: React.FC = () => {
  const [isEnabled] = useDecision('google-ads')
  const googleAdsConfig = useIntegration('google-ads')

  if (!googleAdsConfig || !googleAdsConfig.options) {
    throw new Error(
      'It is not possible to initialize Google Ads without configuration'
    )
  }

  const { id } = googleAdsConfig.options

  if (!wasInitialized && isEnabled) {
    function gtag(...args) {
      window.dataLayer.push(args)
    }
    window.dataLayer = window.dataLayer || []
    window.gtag = window.gtag || gtag
    gtag('js', new Date())
    gtag('config', id)

    const scriptTag = document.createElement('script')
    scriptTag.type = 'text/javascript'
    scriptTag.async = true
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${id}`

    window.document.body.appendChild(scriptTag)

    wasInitialized = true
  }
  return null
}

interface GoogleAdsIntegration {
  id: string
}

export function googleAdsIntegration(
  options: GoogleAdsIntegration
): IntegrationConfig {
  const { title, hex, path } = siGoogleads
  const color = `#${hex}`
  const contrastColor = getForegroundColor(color)
  const Icon = createIconComponentFromSimpleIconsSvgPath(title, path)
  const lang =
    typeof window !== 'undefined' ? window.navigator.language : 'en-US'

  return {
    id: 'google-ads',
    title,
    category: 'Marketing Campaigns',
    color,
    contrastColor,
    Icon,
    privacyPolicyUrl: `https://policies.google.com/privacy?hl=${lang}`,
    description:
      'We use Google Ads to improve our marketing campaigns. Enabling this helps us to spend less money on advertisement.',
    WrapperComponent,
    options,
  }
}
