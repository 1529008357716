const themeConfig = require('./tailwind.config.js')

const deTranslation = require('./translations/de.json')
const enTranslation = require('./translations/en.json')

module.exports = {
  // Configure the MDX Suite
  themeConfig,
  // List your supported locales
  langs: ['en', 'de'],
  defaultLocale: 'de',
  // This maps locales to path prefixes
  localeMap: {
    en: 'en',
    de: '',
  },
  // This maps content types to path prefixes.
  pageTypeMap: {
    page: null,
    blogPost: 'blog',
    summerSchool: 'summer-school',
    boardingSchool: 'boarding-school',
  },
  pageTypeParentMenuItemIds: {
    blogPost: '29jrLrDcj3NRa1KjWwlLoP',
    summerSchool: '6aBqpoUtF4Ezsxl05pJkEv',
    boardingSchool: '3ZtjFH3wUMyuzq8sH2Qa9k',
  },
  translations: {
    en: {
      translation: enTranslation,
    },
    de: {
      translation: deTranslation,
    },
  },
  mediaCollections: {
    screen: {
      selector: [
        'Section[backgroundImageId]',
        'Viewport[backgroundImageId]',
        'Header[backgroundImageId]',
        'FloatingImage[imageId]',
        'HugeOverlap[backgroundImageId]',
        'Image[id][contextKey="screen"]',
        'Video[id][contextKey="screen"],Video[id]:not([contextKey])',
      ].join(','),
      /**
       * @param el See: https://github.com/cheeriojs/cheerio#the-dom-node-object
       */
      attribute: (el) => {
        switch (el.name) {
          case 'video':
          case 'image':
            return 'id'
          case 'floatingimage':
            return 'imageId'
          default:
            return 'backgroundImageId'
        }
      },
    },
    full: {
      selector: [
        'Image[id][contextKey="full"],Image:not([contextKey])',
        'Box[backgroundImageId][contextKey="full"]',
        'BoxCarouselSlide[backgroundImageId][contextKey="full"]',
        'Card[imageId][contextKey="full"]',
        'Video[id][contextKey="full"]',
        'BoxVideo[videoId][contextKey="full"]',
        'ShadowImage[id][contextKey="full"]',
        'ColumnOverlap[backgroundImageId][contextKey="full"]',
      ].join(','),
      attribute: (el) => {
        switch (el.name) {
          case 'box':
          case 'boxcarouselslide':
          case 'columnoverlap':
            return 'backgroundImageId'
          case 'card':
            return 'imageId'
          case 'boxvideo':
            return 'videoid'
          default:
            return 'id'
        }
      },
    },
    half: {
      selector: [
        'Image[id][contextKey="half"]',
        'Box[backgroundImageId][contextKey="half"]',
        'BoxCarouselSlide[backgroundImageId][contextKey="half"]',
        'Card[imageId][contextKey="half"]',
        'Video[id][contextKey="half"]',
        'BoxVideo[videoId][contextKey="half"]',
        'ShadowImage[id]:not([contextKey]),ShadowImage[id][contextKey="half"]',
        'ColumnOverlap[backgroundImageId][contextKey="half"]',
      ].join(','),
      attribute: (el) => {
        switch (el.name) {
          case 'box':
          case 'boxcarouselslide':
          case 'columnoverlap':
            return 'backgroundImageId'
          case 'card':
            return 'imageId'
          case 'boxvideo':
            return 'videoid'
          default:
            return 'id'
        }
      },
    },
    third: {
      selector: [
        'Image[id][contextKey="third"]',
        'Box[backgroundImageId][contextKey="third"]',
        'BoxCarouselSlide[backgroundImageId][contextKey="third"]',
        'Card[imageId][contextKey="third"]',
        'Video[id][contextKey="third"]',
        'BoxVideo[videoId][contextKey="third"]',
        'ShadowImage[id][contextKey="third"]',
        'ColumnOverlap[backgroundImageId]:not([contextKey]),ColumnOverlap[backgroundImageId][contextKey="third"]',
      ].join(','),
      attribute: (el) => {
        switch (el.name) {
          case 'box':
          case 'boxcarouselslide':
          case 'columnoverlap':
            return 'backgroundImageId'
          case 'card':
            return 'imageId'
          case 'boxvideo':
            return 'videoid'
          default:
            return 'id'
        }
      },
    },
  },
}
