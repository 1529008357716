import React, { Suspense, useMemo } from 'react'
import propTypes from 'prop-types'
import { useLocation } from '@reach/router'
import { I18nextProvider } from 'react-i18next'

import { useI18n } from '../../i18next'
import { ConsentManagerWrapper } from '../../consent-manager'
import LazyComponent from '../lazy/lazy-component'
import Loading from '../lazy/loading'
import { Footer } from '../footer'
import Popup from './popup'

import '../../global.css'

const SchoolCarousel = React.lazy(
  () => import(/* webpackChunkName: "layout-carousel" */ '../school-carousel')
)

const Layout = ({ children, pageContext }) => {
  const location = useLocation()

  const isDocs = useMemo(
    () =>
      (location.pathname.indexOf('/docs') === 0 &&
        location.pathname.indexOf('/docs/preview') === -1) ||
      location.pathname.indexOf('/contentful') === 0,
    [location]
  )

  const i18n = useI18n(pageContext.locale)

  return (
    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
      <ConsentManagerWrapper>
        <main className="flex flex-col min-h-screen bg-root-background antialiased">
          <Popup pageContext={pageContext} />
          <div className="flex-auto w-full">{children}</div>
          {!isDocs && (
            <>
              <LazyComponent>
                <Suspense fallback={<Loading />}>
                  <SchoolCarousel />
                </Suspense>
              </LazyComponent>
              <Footer />
            </>
          )}
        </main>
      </ConsentManagerWrapper>
    </I18nextProvider>
  )
}

Layout.propTypes = {
  children: propTypes.node.isRequired,
}

export default Layout
