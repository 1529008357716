import React, { useContext } from 'react'
import propTypes from 'prop-types'
import clsx from 'clsx/lite'

import IconsContext from '../contexts/icons'

import './icon.css'

/**
 * Renders an icon. Icons are registered via IconsContext in your gatsby-browser.js
 *
 * @example
 * <Icon icon="star" />
 * <Icon icon="instagram" />
 * <Icon icon="facebook" color="#4267B2" />
 * <Icon icon="youtube" color="red" />
 */
const Icon = ({
  icon,
  svgProps,
  verticalAlign,
  color,
  className,
  display,
  ...props
}) => {
  const icons = useContext(IconsContext)
  const IconData = icons.get(icon)
  if (!IconData) {
    throw new Error(`Unable to locate icon "${icon}"`)
  }
  const ActualIcon = IconData.icon
  return (
    <div
      className={clsx(
        `icon-wrapper`,
        color && `text-${color}`,
        className,
        display === 'block' && 'block'
      )}
      {...props}
      style={{
        verticalAlign,
        transform: IconData.scale && `scale(${IconData.scale})`,
      }}
    >
      <ActualIcon {...svgProps} />
    </div>
  )
}

Icon.defaultProps = {
  verticalAlign: 'middle',
  display: 'inline-block',
}

Icon.propTypes = {
  /** Name of the icon to render. The available icons can be found in the <Link to="/docs/style-guide/#icons">theme documentation for icons</Link> */
  icon: propTypes.string.isRequired,
  /** Color of the icon. Will use current text color by default.*/
  color: propTypes.string,
  /** Adjust the vertical align of the icon. Useful values: top, bottom, middle, baseline, text-top, text-bottom */
  verticalAlign: propTypes.string,
  svgProps: propTypes.object,
  display: propTypes.oneOf(['block', 'inline-block']),
}

export default Icon
