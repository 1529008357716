import React, { useContext } from 'react'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx/lite'

import Image from '../../components/image'
import MdxSuiteContext from '../../contexts/mdx-suite'

import CTA from '../mdx/link/cta'
import Overlap from '../overlap'

import { useFormatSupportLineBreaks } from '../../hooks/formatText'

import './blog-post-teaser.css'

const BlogPostTeaser = ({ blogPost, ...props }) => {
  const {
    pageContext: { locale: activeLocale },
  } = useContext(MdxSuiteContext)
  const { t } = useTranslation()
  const formattedTitle = useFormatSupportLineBreaks(blogPost.title)
  return (
    <div
      className={clsx('flex flex-col h-full', props.hideOnDesktop && 'hidden')}
    >
      <div>
        <Overlap
          image={
            blogPost.image && (
              <Image
                className="absolute! inset-0"
                imageData={blogPost.image}
                fit="cover"
              />
            )
          }
        >
          <h1
            className="my-0 text-xl leading-relaxed flex items-center"
            styles={{ minHeight: '6rem' }}
          >
            {formattedTitle}
          </h1>
          <div className="blog-post-teaser text-sm text-gray-800 md:text-gray-700 mb-8 mt-2 py-2">
            {Intl.DateTimeFormat(activeLocale).format(
              new Date(blogPost.publicationDate)
            )}{' '}
            -{' '}
            {t('newsTimeToRead', {
              minutes: blogPost.content.childMdx.timeToRead,
            })}
          </div>

          <div className="mt-8">
            <CTA id={blogPost.sys.pageId}>{t('newsReadMore')}</CTA>
          </div>
        </Overlap>
      </div>
    </div>
  )
}

BlogPostTeaser.defaultProps = {
  hideOnDesktop: false,
}

BlogPostTeaser.propTypes = {
  blogPost: propTypes.object.isRequired,
  hideOnDesktop: propTypes.bool,
}

export default BlogPostTeaser
